import * as React from "react"
import { Link } from "gatsby"
import { useState, useContext } from "react"
import { WarningOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
// import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContinueCheckout from '../components/ContinueCheckout.js'
import PaymentSecurity from '../components/PaymentSecurity.js'
import Progress from '../components/Progress.js'
import EndCheckout from '../components/EndCheckout.js'
import {CheckoutContext} from "../context/CheckoutContext"
import Auth from '../components/Auth'
import Footer from '../components/Footer.js'

import getStripe from '../stripe'


// const StripeCheckout = (props) => {


const Payment = () => {
  const context = useContext(CheckoutContext)

  const [loading, setLoading] = useState(false)

  const redirectToStripe = async (r) => {
      const stripe = await getStripe()
      // console.log('awaitingsd', r)
      // When the customer clicks on the button, redirect them to Checkout.

      const result = await stripe.redirectToCheckout({

        sessionId: r.id,
      });

      if (result.error) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `r.error.message`.
              console.log(result.error)

              setLoading(false);
        }
    
  }

  const redirectToCheckout = async event => {
      event.preventDefault()
      
      const stripe = await getStripe()
      
      console.log('checkout', context.getPlanDetails())

      const planDetails = context.getPlanDetails()
      const deliveryDetails = context.getDeliveryDetails()

      console.log(planDetails.priceKey, deliveryDetails.priceKey)

      // get checkout session id
      setLoading(true);

      fetch(`${process.env.GATSBY_API_BASE}/verif/user/checkout`, { // TODO: register
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${context.sessionToken}`
          },
          body: JSON.stringify({
            price: planDetails.priceKey,
            delivery: context.delivery,
            deliveryPrice: planDetails.deliveryPrice,
            gift: context.gift,
            giftReceiverCountryCode: context.giftCountryCode,
            giftReceiverPhone: context.giftPhone,
            giftReceiverName: context.giftName
          })
        })
        .then(res => res.json())
        .then(
          (result) => {
            console.log('response from checkout', result)
            if(!result.id) {
              alert('Kļūda sākot maksājumu. Lūdzu sazinies ar mums vai sāc no sākuma.')
              setLoading(false);
              return;
            }

            redirectToStripe(result);

            
            
          },
          (error) => {
            setLoading(false);
            console.log(error)
            // setError(error);
            
          }
        )

    }



    return (
        <main class="checkout">
        <section class="section">

          <div className="container">
      
        <Helmet title="Heyday ziedu piegāde" defer={false} />
        <Auth required to="/subscribe" />

        <div class="columns is-mobile">
          <div class="column pt-0">
            <Progress value={75} />
          </div>
          <div class="column is-one-third pt-0">
            <EndCheckout />
          </div>
        </div>


      <h1 class="title is-1">Gandrīz esi galā</h1>

       

     <div class="content">

      <div class="card checkout-col">
          <div class="card-content">

            {/* <div className="columns is-mobile">
              <div className="column">
              <p class="title is-5">Abonements</p>
              <p>{context.getPlanDetails().period}</p>


              </div>
              <div className="column">
              <p class="title is-5">Saņēmējs</p>
              <p>{context.gift ? 'Dāvana' : 'Tu'}</p>

              </div>
            </div>
             <hr/> 
            */}
              

              

              

              <p class="title is-5">Pirmā piegāde</p>
              <p>Piegāde notiks nākamajā ceturtdienā, pirms tam saskaņojot laiku ar Tevi.</p>
              {/* <p class="notification is-success"><span class="icon"><WarningOutline color="#fff" /></span> Izvēlēties datumu un laiku, varēsi pēc apmaksas.</p> */}

              <hr/>

              <p class="title is-5">Piegādes adrese</p>
              <p class=""><span class="icon"><WarningOutline color="#ccc" /></span> Adresi jānorāda nākamajā solī - kopā ar kartes informāciju.</p>


              <hr/>

              <p>Veicot maksājumu, piekrītu <a href="/terms" target="_blank">pakalpojuma noteikumiem</a>.</p>

          </div>
        </div>

      
      
     

        {/* <div className="columns is-mobile">
          <div className="column">
            <strong>Saņēmējs</strong>
          </div>
          <div className="column">Tu</div>
        </div>
        <div className="columns is-mobile">
          <div className="column">
            <strong>Saņēmējs</strong>
          </div>
          <div className="column">Tu</div>
        </div>
        <div className="columns is-mobile">
          <div className="column">
            <strong>Saņēmējs</strong>
          </div>
          <div className="column">Tu</div>
        </div> */}

        {/* <div class="card" data-sal="slide-up">
            <div class="card-content">
                <p class="title is-5">
                Bankas karte
                </p>
                <ul>
                    <li>Tava kartes informācija ir drošībā</li>
                    <li>Maksājumu apstrādā Stripe - sertificēts partneris</li>
                </ul>

                <hr />
                <a href="#" class="button is-success is-fullwidth is-rounded">
                    <span class="icon is-small pr-5">
                        <span class="ion-ionic">
                            <CheckmarkSharp color="#fff" />
                        </span>
                        
                    </span>
                    Tu izvēlējies šo
                </a>
            </div>
        </div> */}

</div>  






      <div class="has-text-centered mt-5">

        <PaymentSecurity />


        {/* <StripeCheckout /> */}

      </div>

      {/* <p>

        {process.env.NODE_ENV === "development" ? (
          <>
            Dev
          </>
        ) : null}
        <br />

        <Link to="/">Home</Link>.
      </p> */}

    </div>
      </section>

      <Footer />
      <ContinueCheckout loading={loading} continue={redirectToCheckout} final />

    </main>
  )
}

export default Payment
