import * as React from "react"
import { Link } from "gatsby"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import {CheckoutContext} from "../context/CheckoutContext"
import {useContext} from 'react'

const ContinueCheckout = (props) => {
   

    const handleClick = (e) => {
        e.preventDefault();
        if(typeof props.continue === 'function') {
            props.continue(e)
        } else {
            navigate(props.continue)
        }

    }
    
    
    const context = useContext(CheckoutContext)

    const plan = context.getPlanDetails();
    const delivery = context.getDeliveryDetails();

    return (<><div class="continue card">
        
    <div class="container is-max-desktop">
       <section class="section pt-5 pb-5 has-text-centered  ">
        {!props.read 
            ? <button class={props.loading ? "button is-primary is-rounded is-large is-fullwidth mb-2 is-loading" : "button is-primary is-rounded is-large is-fullwidth mb-2"} onClick={handleClick}>{props.final ? "Turpināt uz apmaksu" : "Turpināt"}</button>
            : null }
        <p class="pb-2"><strong>€{plan.price}</strong> × {plan.period.toLowerCase()}{context.gift ? `, dāvana` : null} <Link to="/subscribe">Mainīt</Link></p>       
        <p>{delivery.deliveryPrice ? `Piegāde ārpus Rīgas — €${delivery.deliveryPrice}` : 'Piegāde Rīgā bezmaksas'} </p> 
        {/* <p class="pt-2">Bez fiksēta termiņa, apturi kad vēlies</p> */}
       </section>
        </div>
    </div></>)
}

export default ContinueCheckout